<template>
  <!--
  *@des: 青年人才购房补贴
  -->
  <section class="form-wrapper">
    <van-form ref="form">
      <van-field
        readonly
        disabled
        v-model="form.applyName"
        name="applyName"
        label="姓名"
        placeholder="请输入姓名"
        required
      />
      <van-field-select
        v-model="form.idCardType"
        :options="idCardTypes"
        :fieldProps="{
          label: '证件类型',
          required: true,
          readonly: true,
          disabled: true,
          placeholder: '请选择证件类型',
        }"
      ></van-field-select>
      <van-field
        v-model="form.phone"
        name="phone"
        label="手机号"
        placeholder="请输入手机号"
        required
        maxlength="11"
      />
      <van-field
        readonly
        disabled
        v-model="form.idCardNum"
        name="idCardNum"
        label="证件号码"
        placeholder="请输入证件号码"
        required
      />

      <van-field-area
        v-model="form.domicile"
        name="domicile"
        :fieldProps="{
          required: true,
          label: '户籍地址',
          placeholder: '请选择户籍地址',
        }"
        defaultField=""
        defalutProCode="41"
      />

      <!-- 学历信息 -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >学历信息</van-divider
        >
        <van-field-select
          v-model="form.education"
          :options="educations"
          :fieldProps="{
            label: '学历',
            placeholder: '请选择学历',
          }"
        ></van-field-select>
        <van-field-select
          v-model="form.educationType"
          :options="educationTypes"
          :fieldProps="{
            label: '学历形式',
            placeholder: '请选择学历形式',
          }"
        ></van-field-select>
        <van-field
          v-model="form.college"
          name="college"
          label="毕业院校"
          placeholder="请输入毕业院校"
          maxlength="100"
        />
        <van-field
          v-model="form.major"
          name="major"
          label="所学专业"
          placeholder="请输入所学专业"
          maxlength="100"
        />

        <van-field-calendar
          v-model="form.graduateDate"
          name="graduateDate"
          label="毕业时间"
          placeholder="毕业时间"
          :fieldProps="{
            label: '毕业时间',
            placeholder: '请选择',
          }"
        />
      </div>

      <!-- 职业技能等级证书 -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >职业技能等级证书</van-divider
        >
        <van-field-select
          v-model="form.skillLevel"
          :options="skillLevels"
          :fieldProps="{
            label: '职业技能等级',
            placeholder: '请选择职业技能等级',
          }"
        ></van-field-select>
        <van-field
          v-model="form.skillCertName"
          name="skillCertName"
          label="证书名称"
          placeholder="请输入证书名称"
          maxlength="100"
        />
        <van-field
          v-model="form.skillCertCode"
          name="skillCertCode"
          label="证书编号"
          placeholder="请输入证书编号"
          maxlength="100"
        />

        <van-field-calendar
          v-model="form.skillCertTime"
          name="skillCertTime"
          label="获得日期"
          placeholder="获得日期"
          :fieldProps="{
            label: '获得日期',
            placeholder: '请选择获得日期',
          }"
        />
      </div>

      <!-- 房屋信息 -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >房屋信息</van-divider
        >
        <van-field-select
          v-model="form.houseBelong"
          :options="houseBelong"
          :fieldProps="{
            required: true,
            label: '购房者',
            placeholder: '请选择购房者',
          }"
        ></van-field-select>
        <van-field
          :value="houseStr"
          name="house"
          clickable
          label="购买住房地址"
          placeholder="请选择购买住房地址"
          required
          @click="showHousePicker = true"
        />
        <van-popup v-model="showHousePicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="houseCitys"
            @cancel="showHousePicker = false"
            @confirm="onHouseConfirm"
          />
        </van-popup>
        <van-field
          v-model="form.houseAddress"
          name="houseAddress"
          label="购房详细地址"
          placeholder="请输入购房详细地址"
          required
          maxlength="100"
        />
      </div>
      <!-- 家庭关系 -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >家庭关系</van-divider
        >
        <div class="notices">
          <p class="notice-item">* 1、家庭成员须依据实际情况，未婚无需填写。</p>
        </div>
        <div v-for="(item, i) in form.family" :key="i">
          <van-field-select
            v-model="item.familyTies"
            :options="familyTies"
            :fieldProps="{
              label: '关系',
              required: item.familyName || item.famIdCardNum ? true : false,
              placeholder: '请选择关系',
            }"
          ></van-field-select>
          <van-field
            v-model="item.familyName"
            name="familyName"
            label="姓名"
            placeholder="请输入姓名"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="20"
          />
          <van-field
            v-model="item.famIdCardType"
            name="famIdCardType"
            label="证件类型"
            placeholder="请输入证件类型"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="100"
            disabled
          />
          <van-field
            v-model="item.famIdCardNum"
            name="famIdCardNum"
            label="身份证号"
            placeholder="请输入身份证号"
            :required="item.familyName || item.famIdCardNum ? true : false"
            maxlength="18"
          />
          <van-field-area
            v-model="item.famDomicile"
            name="famDomicile"
            :fieldProps="{
              required: item.familyName || item.famIdCardNum ? true : false,
              label: '户籍所在地',
              placeholder: '请选择户籍所在地',
            }"
            defaultField=""
          />
          <p class="fam-btns">
            <van-button class="btn" type="default" size="mini" @click="onAdd"
              >新增</van-button
            >
            <van-button
              class="btn"
              type="danger"
              size="mini"
              @click="onDelete(i)"
              >删除</van-button
            >
          </p>
        </div>
      </div>

      <!-- 申请人社保卡信息（金融账户） -->
      <div>
        <van-divider
          content-position="left"
          :style="{
            color: '#1989fa',
            borderColor: '#1989fa',
            padding: '0rem 0.3rem',
          }"
          >申请人社保卡信息（金融账户）</van-divider
        >
        <div class="notices">
          <p class="notice-item">
            *
            1、开户银行具体到支行和行号，若不清楚请拨打银行客服热线进行咨询，以下内容请务必准确无误，否则将影响补贴到账进度或产生退件。
          </p>
        </div>
        <van-field
          v-model="form.bankId"
          name="bankId"
          label="社会保障卡开户银行"
          placeholder="请输入社会保障卡开户银行"
          required
          maxlength="100"
        />
        <van-field
          v-model="form.bankAccount"
          name="bankAccount"
          label="社会保障卡金融账号"
          placeholder="请输入社会保障卡金融账号"
          required
          maxlength="20"
        />

        <van-field
          v-model="form.bankBranch"
          name="bankBranch"
          label="社会保障卡支行名称"
          placeholder="请输入社会保障卡支行名称"
          required
          maxlength="20"
        />
        <van-field
          v-model="form.bankCode"
          name="bankCode"
          label="行号"
          placeholder="请输入行号"
          required
          maxlength="20"
        />
      </div>
    </van-form>
  </section>
</template>

<script>
import formMixin from "../mixin";
import Schema from "async-validator";

export default {
  name: "young-house-subsidy",
  mixins: [formMixin],
  data() {
    return {
      form: {
        applyName: "", //姓名
        idCardType: "居民身份证", //证件类型
        phone: "",
        idCardNum: "", //证件号码
        bankId: "", //社会保障卡开户银行
        bankAccount: "", //社会保障卡金融账号
        domicile: [], //户籍地址
        domicileTime: "",
        bankBranch: "", //请输入支行名称
        house: "",
        houseAddress: "",
        talentType: "",
        //学历信息
        college: "",
        major: "",
        education: "",
        educationType: "",
        graduateDate: "",
        //技能信息
        skillLevel: "", //职业技能等级
        skillCertName: "", //证书名称
        skillCertCode: "", //证书编号
        family: [
          {
            familyTies: "",
            familyName: "",
            famIdCardType: "居民身份证",
            famIdCardNum: "",
            famDomicile: [],
          },
        ],
      },
      formRules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
          },
          {
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请检查手机号格式",
          },
        ],
        bankId: {
          required: true,
          message: "请输入开户银行",
        },
        bankAccount: {
          required: true,
          message: "请输入金融账号",
        },
        domicile: {
          type: "array",
          required: true,
          message: "请选择户籍地址",
        },
        house: {
          type: "array",
          required: true,
          message: "请选择购房地址",
        },
        houseAddress: {
          required: true,
          message: "请输入详细地址",
        },
        houseBelong: {
          required: true,
          message: "请选择购房者",
        },
        bankBranch: {
          required: true,
          message: "请输入社会保障卡支行名称",
        },
        bankCode: {
          required: true,
          message: "请输入行号",
        },
      },
      formValidator: null,
      familyformRules1: {
        familyTies: {
          required: false,
        },
        familyName: {
          required: false,
        },
        famIdCardNum: {
          required: false,
        },
        famDomicile: {
          required: false,
        },
      },
      familyformRules2: {
        familyTies: {
          required: true,
          message: "请选择家庭成员关系",
        },
        familyName: {
          required: true,
          message: "请输入姓名",
        },
        famIdCardNum: [
          {
            required: true,
            message: "请输入身份证号",
          },
          {
            pattern:
              /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/,
            message: "请检查身份证号格式",
          },
        ],
        famDomicile: {
          required: true,
          type: "array",
          message: "请选择户籍地址",
        },
      },
      familyFormValidator1: null,
      familyFormValidator2: null,
      idCardTypes: [
        {
          value: "居民身份证",
          label: "居民身份证",
        },
      ],
      showHousePicker: false,
      houseCitys: [
        {
          value: "41",
          text: "河南省",
          children: [
            {
              value: "4101",
              text: "郑州市",
              children: [
                {
                  value: "410173",
                  text: "航空港区",
                },
              ],
            },
          ],
        },
      ],
      talentTypes: [
        {
          value: "国内大专以上学历人才",
          label: "国内大专以上学历人才",
        },
        {
          value: "技师（技工院校预备技师）以上人才",
          label: "技师（技工院校预备技师）以上人才",
        },
      ],
      educations: [
        {
          value: "大专",
          label: "大专",
        },
        {
          value: "本科",
          label: "本科",
        },
        {
          label: "研究生",
          value: "研究生",
        },
      ],
      educationTypes: [
        {
          value: "统招全日制",
          label: "统招全日制",
        },
        {
          value: "成人高考",
          label: "成人高考",
        },
        {
          label: "远程教育",
          value: "远程教育",
        },
        {
          label: "自学考试",
          value: "自学考试",
        },
        {
          label: "国家开放大学",
          value: "国家开放大学",
        },
        {
          label: "其他",
          value: "其他",
        },
      ],
      skillLevels: [
        {
          value: "首席技师",
          label: "首席技师",
        },
        {
          value: "特级技师",
          label: "特级技师",
        },
        {
          label: "高级技师",
          value: "高级技师",
        },
        {
          label: "技师",
          value: "技师",
        },
        {
          label: "预备技师",
          value: "预备技师",
        },
      ],
      //家庭关系
      familyTies: [
        {
          value: "配偶",
          label: "配偶",
        },
        {
          value: "儿子(未成年)",
          label: "儿子(未成年)",
        },
        {
          label: "女儿(未成年)",
          value: "女儿(未成年)",
        },
      ],

      houseBelong: [
        {
          label: "本人",
          value: "本人",
        },
        {
          label: "配偶",
          value: "配偶",
        },
        {
          label: "未成年子女",
          value: "未成年子女",
        },
      ],
    };
  },
  computed: {
    houseStr() {
      if (!this.form.house.length) {
        return "";
      } else {
        let [a, b, c] = this.form.house;
        let pro = this.houseCitys.filter((item) => item.value === a)[0];
        let city = pro.children.filter((item) => item.value === b)[0];
        let county = city.children.filter((item) => item.value === c)[0];
        return `${pro.text}/${city.text}/${county.text}`;
      }
    },
  },
  created() {
    this.initFamilyFormValidator();
  },
  methods: {

    /*
     *@des: 选择购房地址
     */
    onHouseConfirm(a, b) {
      let [index1, index2, index3] = b;
      this.form.house = [
        this.houseCitys[index1].value,
        this.houseCitys[index1].children[index2].value,
        this.houseCitys[index1].children[index2].children[index3].value,
      ];
      this.showHousePicker = false;
    },
    /*
     *@des: 新增家庭成员
     */
    onAdd() {
      this.form.family.push({
        familyTies: "",
        familyName: "",
        famIdCardType: "居民身份证",
        famIdCardNum: "",
        famDomicile: [],
      });
    },
    /*
     *@des: 删除家庭成员
     */
    onDelete(index) {
      if (this.form.family.length === 1) {
        this.$toast("最后一条不能删除");
        return;
      }
      this.form.family.splice(index, 1);
    },
    /*
     *@des: 初始化校验
     */
    initFamilyFormValidator() {
      this.formValidator = new Schema(this.formRules);
      this.familyFormValidator1 = new Schema(this.familyformRules1);
      this.familyFormValidator2 = new Schema(this.familyformRules2);
    },
    /*
     *@des: 校验家庭关系
     */
    async validateFamilyForm() {
      let len = this.form.family.length,
        isFormCorrect = true; //校验状态

      for (let i = 0; i < len; i++) {
        let item = this.form.family[i];
        try {
          //判断姓名，身份证号是否已填，不同校验
          if (item.familyName || item.famIdCardNum) {
            await this.familyFormValidator2.validate(item);
          } else {
            await this.familyFormValidator1.validate(item);
          }

          isFormCorrect = true;
        } catch ({ errors }) {
          isFormCorrect = false;

          let message = errors[0].message;
          this.$toast(`家庭关系第${i + 1}项${message}`);
          break;
        }
      }

      return isFormCorrect;
    },
    /*
     *@des: 校验表单
     */
    validate() {
      return new Promise((resolve, reject) => {
        this.formValidator
          .validate(this.form, (errors) => {
            if (errors && errors.length) {
              this.$toast(errors[0].message);
            }
          })
          .then(async () => {
            let familyFormStatus = await this.validateFamilyForm();
            if (familyFormStatus) {
              let family = this.form.family
                .filter((item) => (item.familyName ? true : false))
                .map((item) => {
                  let [famProCode, famCityCode, famCountyCode] =
                    item.famDomicile;
                  return {
                    ...item,
                    famProCode,
                    famCityCode,
                    famCountyCode,
                  };
                });
              let [proCode, cityCode, countyCode] = this.form.domicile;
              let [houseProCode, houseCityCode, houseCountyCode] =
                this.form.house;
              resolve({
                ...this.form,
                proCode,
                cityCode,
                countyCode,
                houseProCode,
                houseCityCode,
                houseCountyCode,
                family,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-wrapper {
  margin-top: 0.2rem;
  background: #fff;
  .title-gutter {
    width: fit-content;
    margin-left: 0.3rem;
    padding: 0.3rem 0.1rem 0rem 0rem;
    font-size: 0.26rem;
    line-height: 0.26rem;
    border-bottom: 2px solid #3c37d2;
  }
  .fam-btns {
    padding: 0.3rem;
    margin-bottom: 0.3rem;
    border-bottom: 1px solid #eee;
    .btn {
      width: 1.5rem;
      margin-right: 0.15rem;
    }
  }
}
.tip {
  margin: 0.2rem;
}
</style>